<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
        <v-row>
          
          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              v-model="id_customer"
              :items="customers"
              item-text="nombre"
              item-value="codigo"
              label="Escoge un Productor"
              clearable
              rounded
              solo
              hide-details
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              v-model="id_coffee"
              :items="types"
              item-text="nombre"
              item-value="codigo"
              label="Escoge la clase de Café"
              clearable
              rounded
              solo
              hide-details
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              v-model="id_place"
              :items="places"
              item-text="name"
              item-value="id"
              label="Escoge la ubicación"
              clearable
              rounded
              solo
              hide-details
            ></v-autocomplete>
          </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRangeText"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                clearable
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap;">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(dates);
                    updateDateFilter();
                  "
                  >Aceptar</v-btn
                >
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" sm="12" md="2">
            <v-btn
              color="success"
              class="ma-2 white--text"
              @click="getByCustomer"
              block
            >
              Filtrar <v-icon right >
                fas fa-filter
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <v-btn
              :loading="loading3"
              :disabled="loading3"
              color="warning"
              class="ma-2 white--text"
              @click="getAll"
              block
            >
              Ver Todo
              <v-icon right >
                fas fa-eye
              </v-icon>
            </v-btn>
          </v-col>

        </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="date"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >
    <template v-slot:[`item.datetime`]="{ item }">
        {{ dateFormat(item.datetime) }}
    </template>
    <template v-slot:[`item.precio`]="{ item }">
        {{ numberWithCommas(item.precio) }}
    </template>
    <template v-slot:[`item.transference`]="{ item }">
        <div v-if="item.transference==0">
          Efectivo
        </div>
        <div v-else>
          Transferencia
        </div>
    </template>
    <template v-slot:[`item.total2`]="{ item }">
        {{ numberWithCommas(item.total2) }}
    </template>
    <template slot="body.append">
      <tr class="gray--text">
        <!-- <th class="title"></th> -->
        
        <th colspan="10" class="title">
        <v-row class="pt-2">
          <v-col cols="12" sm="12" md="3">
            Cantidad: {{ numberWithCommas((parseFloat(sumField('cant_anterior'))).toFixed(2)) }}
          </v-col>
          <v-col cols="12" sm="12" md="3">
            Efectivo: {{ numberWithCommas((( parseFloat(sumField('total2')) - parseFloat(sumField('money_transference'))).toFixed(2) )) }}
          </v-col>
          <v-col cols="12" sm="12" md="3">
            Transferencia: {{ numberWithCommas((parseFloat(sumField('money_transference'))).toFixed(2)) }}
          </v-col>
          <v-col cols="12" sm="12" md="3">
            Total: {{ numberWithCommas((parseFloat(sumField('total2'))).toFixed(2)) }}
          </v-col>
        </v-row>
        </th>
        
      </tr>
    </template>
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';

export default {
  name: "pays",
  data: () => ({
    currentItems: [],
    samplings: [],
    customers: [],
    types: [],
    places: [],
    dates: [],
    id_customer: null,
    id_coffee: null,
    id_place: null,
    loading: false,
    dialogDelete: false,
    loader: null,
    loading3: false,
    modal: false,
    search: "",
    loading: false,
    titulo: "Pago a Productores",
    headers: [
      { text: "Fecha", align: "left", value: "datetime"},
      { text: "RTN", align: "left", value: "rtn" },
      { text: "Productor", align: "left", value: "nombre" },
      { text: "Clase de Café", align: "left", value: "product" },
      { text: "Factura", value: "bill" },
      { text: "Tipo", value: "transference" },
      { text: "Cantidad", value: "cant_anterior" },
      { text: "Precio", value: "precio" },
      // { text: "Efectivo", value: "cash" },
      // { text: "Transferencia", value: "transfer" },
      { text: "Total Pagado", value: "total2" },
      // { text: "Cajero", value: "cajero" },
    ],
  }),

  computed: {
    
    ...mapState(["db","headers_db"]),
    dateRangeText: {
        get()
        {
            return this.dates.join(' ~ ')
        },
        set()
        {
            this.dates = []
        }
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
    },
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getSamplings(){
      await axios.get(this.db + 'pays',
        {
          headers: this.headers_db
        }).then(response => {
          response.data.data.forEach(element => {
            if (element.transference == 1) {
              // console.log(element);
              element.money_transference = element.total2;
            }
          });
          this.samplings = this.currentItems = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getSamplings(); })
        });
    },

    async getCustomers(){
      await axios.get(this.db + 'providers',
        {
          headers: this.headers_db
        }).then(response => {
          this.customers = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
        });
    },

    async getTypes(){
      await axios.get(this.db + 'types',
        {
          headers: this.headers_db
        }).then(response => {
          this.types = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getTypes(); })
        });
    },

    async getPlaces(){
      await axios.get(this.db + 'places',
        {
          headers: this.headers_db
        }).then(response => {
          this.places = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getPlaces(); })
        });
    },
    // FIN Metodos base de datos

    // Metodos Generales
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a');
    },
    title() {
      return "Selecciona las fechas a filtrar";
    },
    updateDateFilter() {
      if (this.dates.length > 1) {
        if (moment(this.dates[0], "YYYY-MM-DD") > new Date(this.dates[1])) {
          this.dates = [this.dates[1], this.dates[0]];
        }
      } else {
        this.dates = [this.dates[0], this.dates[0]];
      }
    },

    close() {
      this.dialogDelete = false;
    },
    sumField(key) {
      return this.currentItems.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    getByCustomer() {
      console.log('dates2', this.dates, this.id_coffee, this.id_customer, this.id_place);

      // PLACE
      if (this.id_place && this.id_customer && this.dates[0] && this.dates[1] && this.id_coffee) {
        this.currentItems = this.samplings.filter(doc => {
          let date_db = moment(doc.datetime).format('YYYY-MM-DD');
          return parseInt(doc.id_place) == parseInt(this.id_place) && doc.id_productor == this.id_customer && doc.id_tipo_producto == this.id_coffee && date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }
      
      else if (this.id_place && this.id_customer && this.id_coffee) {
        this.currentItems = this.samplings.filter(doc => {
          return parseInt(doc.id_place) == parseInt(this.id_place) && doc.id_tipo_producto == this.id_coffee && doc.id_productor == this.id_customer;
        });
      }

      else if (this.id_place && this.dates[0] && this.dates[1] && this.id_coffee) {
        this.currentItems = this.samplings.filter(doc => {
          let date_db = moment(doc.datetime).format('YYYY-MM-DD');
          return parseInt(doc.id_place) == parseInt(this.id_place) && doc.id_tipo_producto == this.id_coffee && date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }

      // CUSTOMER
      else if (this.id_customer && this.dates[0] && this.dates[1] && this.id_coffee) {
        this.currentItems = this.samplings.filter(doc => {
          let date_db = moment(doc.datetime).format('YYYY-MM-DD');
          return doc.id_productor == this.id_customer && doc.id_tipo_producto == this.id_coffee && date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }

      else if (this.id_customer && this.dates[0] && this.dates[1]) {
        this.currentItems = this.samplings.filter(doc => {
          let date_db = moment(doc.datetime).format('YYYY-MM-DD');
          return doc.id_productor == this.id_customer && date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }

      else if (this.id_coffee && this.dates[0] && this.dates[1]) {
        this.currentItems = this.samplings.filter(doc => {
          let date_db = moment(doc.datetime).format('YYYY-MM-DD');
          return doc.id_tipo_producto == this.id_coffee && date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }

      else if (this.id_place && this.dates[0] && this.dates[1]) {
        this.currentItems = this.samplings.filter(doc => {
          let date_db = moment(doc.datetime).format('YYYY-MM-DD');
          return parseInt(doc.id_place) == parseInt(this.id_place) && date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }

      else if (this.id_coffee && this.id_customer) {
        this.currentItems = this.samplings.filter(doc => {
          return doc.id_tipo_producto == this.id_coffee && doc.id_productor == this.id_customer;
        });
      }

      else if (this.id_place && this.id_customer) {
        this.currentItems = this.samplings.filter(doc => {
          return parseInt(doc.id_place) == parseInt(this.id_place) && doc.id_productor == this.id_customer;
        });
      }

      else if (this.id_place && this.id_coffee) {
        this.currentItems = this.samplings.filter(doc => {
          return parseInt(doc.id_place) == parseInt(this.id_place) && doc.id_tipo_producto == this.id_coffee;
        });
      }
      
      else if (this.id_customer) {
        this.currentItems = this.samplings.filter(doc => {
          return doc.id_productor == this.id_customer;
        });
      }

      else if (this.id_coffee) {
        this.currentItems = this.samplings.filter(doc => {
          return doc.id_tipo_producto == this.id_coffee;
        });
      }

      else if (this.id_place) {
        this.currentItems = this.samplings.filter(doc => {
          return parseInt(doc.id_place) == parseInt(this.id_place);
        });
      }

      else if (this.dates[0] && this.dates[1]) {
        this.currentItems = this.samplings.filter(doc => {
          let date_db = moment(doc.datetime).format('YYYY-MM-DD');
          return date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }
      else {
        this.currentItems = this.samplings;
      }
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getAll() {
      this.loader = 'loading3'
      this.loading = true;
      this.getSamplings();
      this.getCustomers();
    },
  },
  mounted() {
    this.loading = true;
    this.getSamplings();
    this.getCustomers();
    this.getTypes();
    this.getPlaces();
  },
};
</script>